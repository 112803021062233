interface Extras {
	player: string;
	boundary: string;
	competitor: string;
}

export const oddsExtrasReplacer = (data: string | null, extras: Extras | null) => {
	if (!data) return;
	if (!extras) return data;

	const replacedData = data.replace(/{%player}/g, extras.player).replace(/{%boundary}/g, extras.boundary);

	return replacedData;
};
