import { BettingBonusLevel, BonusType, Currency, Status } from './types';
import i18n from './../../../localisation/i18n';

export const mapForDropdown = (items: string[]) => {
	return items.map((name) => ({ id: name, name: name.split('_').join(' ') }));
};

export const currencyValues: Currency[] = ['RSD', 'BAM', 'EUR', 'USD', 'GBP', 'RUB', 'NGN', 'NAD', 'BWP', 'CDF'];
export const bonusStatusValues: Status[] = ['ACTIVE', 'IN_CREATION', 'INACTIVE', 'DELETED'];
export const bonusTypeValues: BonusType[] = [
	'WELCOME',
	'DEPOSIT',
	'TURNOVER',
	'REGISTRATION',
	'PURE',
	'NEXT_DEPOSIT',
	'BETTING_BONUS',
	'FIRST_DEPOSIT',
];

export const additionalBonusTypeValues: BonusType[] = [
	'WELCOME',
	'DEPOSIT',
	'TURNOVER',
	'PURE',
	'NEXT_DEPOSIT',
	'BETTING_BONUS',
	'FIRST_DEPOSIT',
];

export const bettingBonusLevelValues: BettingBonusLevel[] = ['SPORT', 'TOURNAMENT', 'EVENT'];

export const bettingBonusLevels = mapForDropdown(bettingBonusLevelValues);

export const bonusTypes = mapForDropdown(bonusTypeValues);
export const additionalBonusTypes = mapForDropdown(additionalBonusTypeValues);

export const currencies = mapForDropdown(currencyValues);
export const bonusStatuses = mapForDropdown(bonusStatusValues);
export const targetAmounts = mapForDropdown(['DEPOSIT_AMOUNT', 'BONUS_AMOUNT']);
export const externalProviderStatuses = mapForDropdown(['SHARED', 'EXCLUSIVE']);
export const walletTypes = [
	{ id: 'INTERNET', name: 'INTERNET' },
	{ id: 'FB', name: 'FREE BET' },
];

type ParamState = {
	[key: string]: {
		times: number | null;
		ordinal: number | null;
		ordinalDisabled: boolean;
		onlyFixed: boolean;
		targetAmount: 'BONUS_AMOUNT' | null;
		disableUnlock: boolean;
		bettingFixed?: number;
		disableUnlockDesc?: boolean;
		timezone: string | null;
	};
};
export const paramStateByBonusType: ParamState = {
	WELCOME: {
		times: 1,
		ordinal: 1,
		ordinalDisabled: false,
		onlyFixed: false,
		targetAmount: null,
		disableUnlock: false,
		timezone: null,
	},
	DEPOSIT: {
		times: null,
		ordinal: null,
		ordinalDisabled: false,
		onlyFixed: false,
		targetAmount: null,
		disableUnlock: false,
		timezone: 'Europe/Belgrade',
	},
	TURNOVER: {
		times: null,
		ordinal: null,
		ordinalDisabled: true,
		onlyFixed: true,
		targetAmount: 'BONUS_AMOUNT',
		disableUnlock: false,
		timezone: 'Europe/Belgrade',
	},
	REGISTRATION: {
		times: 1,
		ordinal: 0,
		ordinalDisabled: true,
		onlyFixed: true,
		targetAmount: 'BONUS_AMOUNT',
		disableUnlock: true,
		disableUnlockDesc: true,
		timezone: null,
	},
	PURE: {
		times: null,
		ordinal: null,
		ordinalDisabled: true,
		onlyFixed: true,
		targetAmount: 'BONUS_AMOUNT',
		disableUnlock: true,
		disableUnlockDesc: true,
		timezone: 'Europe/Belgrade',
	},
	NEXT_DEPOSIT: {
		times: 1,
		ordinal: null,
		ordinalDisabled: true,
		onlyFixed: false,
		targetAmount: null,
		disableUnlock: false,
		timezone: null,
	},
	BETTING_BONUS: {
		times: null,
		ordinal: null,
		ordinalDisabled: true,
		onlyFixed: true,
		targetAmount: 'BONUS_AMOUNT',
		disableUnlock: false,
		bettingFixed: 1,
		disableUnlockDesc: true,
		timezone: 'Europe/Belgrade',
	},
	FIRST_DEPOSIT: {
		times: 1,
		ordinal: 1,
		ordinalDisabled: true,
		onlyFixed: false,
		targetAmount: null,
		disableUnlock: false,
		timezone: null,
	},
};

export const fixedSuggestion = [
	{
		text: i18n.t('internet.userBonuses.amount'),
		value: '{amount}',
	},
	{
		text: i18n.t('internet.userBonuses.turnoverAmount'),
		value: '{turnoverAmount}',
	},
	{
		text: `${i18n.t('internet.userBonuses.timesDeposit')} ${i18n.t('internet.userBonuses.canBeApplied')}`,
		value: '{canBeApplied}',
	},
	{
		text: `${i18n.t('internet.userBonuses.timesDeposit')} ${i18n.t('internet.userBonuses.canBeAppliedDaily')}`,
		value: '{canBeAppliedDaily}',
	},
	{
		text: `${i18n.t('internet.userBonuses.deposit')} ${i18n.t('internet.userBonuses.ordinalNumber')}`,
		value: '{depositNumForPayout}',
	},
	{
		text: `${i18n.t('internet.userBonuses.currency')}`,
		value: '{currency}',
	},
	{
		text: i18n.t('internet.userBonuses.minDeposit'),
		value: '{minDeposit}',
	},
	{
		text: i18n.t('internet.userBonuses.maxBonus'),
		value: '{maxValue}',
	},
	{
		text: i18n.t('internet.userBonuses.fixedBonus'),
		value: '{fixedValue}',
	},
	{
		text: i18n.t('internet.userBonuses.percentage'),
		value: '{percentage}',
	},
	{
		text: i18n.t('internet.userBonuses.dateFrom'),
		value: '{dtFrom}',
	},
	{
		text: i18n.t('internet.userBonuses.dateTo'),
		value: '{dtUntil}',
	},
	{
		text: i18n.t('internet.userBonuses.numOfPlays'),
		value: '{bettingMultiplier}',
	},
	{
		text: i18n.t('internet.userBonuses.daysToPlay'),
		value: '{validForDays}',
	},
	{
		text: i18n.t('internet.userBonuses.miQuotaByType'),
		value: '{minQuotaPerOddType}',
	},
	{
		text: i18n.t('internet.userBonuses.minEvents'),
		value: '{minNumberOfEvents}',
	},
	{
		text: i18n.t('internet.userBonuses.minQuota'),
		value: '{minTicketQuota}',
	},
];
