import { axiosInstance } from '../config';
import { UsersSum } from '../../pages/reports/online-report/types';
import { fileNameFromHeaders } from '../../utils/getExportFileName';

export const onlineReport = {
	getUsersSum(params: UsersSum) {
		return axiosInstance.post('/reporting/user_sum_report', params).then(({ data }) => data);
	},

	exportUsersSum(params: UsersSum) {
		return axiosInstance.post('/reporting/export_user_sum_report', params).then((res) => {
			return [fileNameFromHeaders(res.headers, 'online-report'), res.data];
		});
	},
};
