import React, { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import Dropdown from '../../../components/general/Dropdown';
import { Item } from '../../../types/general';

interface Props {
	name: string;
	items: Item[];
	direction?: string;
	searchOption?: boolean;
}

export const RHFSelect: FC<Props> = ({ name, items, direction = 'down', searchOption }) => {
	const { getValues, setValue, control } = useFormContext();

	const defaultValue = getValues(name);

	const item = useWatch({
		control,
		name,
		defaultValue,
	});

	return (
		<Dropdown
			items={items}
			selectedItem={item}
			selectItem={(id: string) => setValue(name, id)}
			margins={['0', '0', '0', '0']}
			icon={'mdi mdi-menu-down'}
			listClass={'list_6_items'}
			direction={direction}
			disableScroll
			withSearch={searchOption}
		/>
	);
};
