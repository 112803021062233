import { SpecialMarket, SpecialOddType } from './../pages/administration/special-markets/types';
import { MarketItem } from './../types/MarketsAdministration';

export const emptyMarket: MarketItem = {
	id: '',
	default: false,
	order: null,
	sport: {
		id: '',
		name: '',
	},
	localisation: {
		de: {
			web: '',
			mobile: '',
			list: '',
			betMedia: '',
		},
		en: {
			web: '',
			mobile: '',
			list: '',
			betMedia: '',
		},
		srb: {
			web: '',
			mobile: '',
			list: '',
			betMedia: '',
		},
	},
	hasBoundaries: false,
	splitTypes: false,
	mix: false,
	playerProps: false,
	oddtypes: [],
	live: false,
	activeForCashier: true,
	activeForTerminal: true,
	activeForMobile: true,
	activeForWeb: true,
	groupBy: 'MARKET',
};

export const emptySpecialMarket: SpecialMarket = {
	...emptyMarket,
	order: null,
	hasBoundaries: false,
	splitTypes: false,
	mix: false,
	playerProps: false,
};

export const emptySpecialOdd: SpecialOddType = {
	id: '',
	order: null,
	name: {},
	description: {},
	keyboardCode: '',
	formula: '',
	bettingDelay: 0,
	riskFactor: 0.01,
};
