// administration
export const ADMINISTRATION_PROMOTION = 'promotions';

// Reports tables
export const REPORTS_TICKETS = 'tickets';
export const REPORTS_TICKETS_REPORT = 'reportTickets';
export const REPORTS_EVENT_STATS = 'eventStats';
export const REPORTS_RESERVATIONS = 'reservations';
export const REPORTS_RESERVATIONS_BY_PLAYERS = 'reservationsByPlayers';
export const REPORTS_CASH_FLOW = 'cashFlow';
export const REPORTS_PROMOTIONS = 'reportsPromotions';
export const REPORTS_STATISTICS = 'statistics';
export const REPORTS_PAYMENT_TRANSACTIONS = 'paymentTransactions';
export const REPORTS_TRANSACTIONS = 'reportsTransactions';
export const REPORTS_ONLINE_REPORTS = 'onlineReport';
export const AML_REPORTS = 'amlReports';

// Internet tables
export const INTERNET_TRANSACTIONS = 'transactions';
export const INTERNET_USERS = 'users';

// Online-Support tables
export const ONLINE_SUPPORT_USERS = 'onlineSupportUsers';

export const ADMINISTRATION_PROMOTION_COLUMNS = {
	ID: 'id',
	NAME: 'name',
	PROMO_CODE: 'promoCode',
	DATE_CREATED: 'dateCreated',
	STATUS: 'status',
	PROMOTION_TYPE: 'promotionType',
	DATE_FROM: 'dateFrom',
	DATE_UNTIL: 'dateUntil',
	CURRENCY: 'currency',
	FIXED_AMOUNT: 'fixedAmount',
	MAX_AMOUNT: 'maxAmount',
	MIN_PAYIN: 'minPayin',
	MIN_TICKET_QUOTA: 'minTicketQuota',
	CAN_BE_APPLIED: 'canBeApplied',
	CREATED_BY: 'createdBy',
};

// Columns Online-Support
export const ONLINE_SUPPORT_USERS_COLUMNS = {
	ID: 'id',
	USERNAME: 'username',
	EMAIL: 'email',
	POLITICIAN: 'politician',
	NATIONAL_ID: 'nationalId',
	NAME: 'name',
	LOCATION: 'location',
	ONLINE_BALANCE: 'onlineBalance',
	FB_BALANCE: 'fb_balance',
	RESERVATION: 'reservation',
	CURRENCY: 'currency',
	TELEPHONE: 'telephone',
	CITY: 'city',
	ADDRESS: 'address',
	CREATED: 'created',
	DESCRIPTION: 'description',
	VERIFIED: 'verified',
};

// Columns Tickets table columns
export const REPORTS_TICKETS_COLUMNS = {
	STATUS: 'status',
	SIGN: 'sign',
	LOCATION: 'location',
	BARCODE: 'barcode',
	TIME: 'time',
	SA: 'sa',
	PAYIN: 'payin',
	CURRENCY: 'currency',
	ACC: 'acc',
	MAX_GAIN: 'maxGain',
	GAIN: 'gain',
	PAYOUT: 'payout',
	LIVE_PAYOUT: 'livePayout',
	BONUS: 'bonus',
	GEO_ENTITY_NAME: 'geoEntityName',
	RISK: 'risk',
	RISK_TAG: 'riskTag',
	USER: 'user',
	BOOKMAKER: 'bookmaker',
	SIMILARITY: 'similarity',
	RISK_GROUP_ID: 'riskGroupId',
};

export const REPORTS_TICKETS_REPORT_COLUMNS = {
	BARCODE: 'barcode',
	USER_FIRST_NAME: 'firstName',
	USER_LAST_NAME: 'lastName',
	NATIONAL_ID: 'nationalId',
	PAYIN_TIME: 'payinTime',
	ORIGINAL_AMOUNT: 'originalAmount',
	PAYOUT_TIME: 'payoutTime',
	ORIGINAL_GAIN: 'originalGain',
	TAX: 'tax',
	PAYOUT: 'payout',
	CITY: 'city',
	STATUS: 'status',
};

// Columns Event Stats table columns
export const REPORTS_EVENT_STATS_COLUMNS = {
	LIST_CODE: 'listCode',
	EVENT: 'event',
	START: 'start',
	SPORT: 'sport',
	MARKET: 'market',
	ODD: 'odd',
	TOTAL_PAY_IN: 'totalPayIn',
	LIVE_PAY_IN: 'livePayIn',
	AVERAGE_PAY_IN: 'averagePayIn',
	AVERAGE_LIVE_PAY_IN: 'averageLivePayIn',
	LIVE_PAY_IN_SINGLE: 'livePayInSingle',
	NUMBER_OF_LIVE_WINING_LOSING: 'numberOfLiveWiningAndLosing',
	AVERAGE_POSSIBLE_PAYOUT: 'averagePossiblePayout',
	CURRENCY: 'currency',
};

// Columns Reservations table
export const REPORTS_RESERVATIONS_COLUMNS = {
	ID: 'id',
	NAME: 'name',
	RESERVED: 'reserved',
	PAID_OUD_AMOUNT: 'paidOutAmount',
	BALANCE: 'balance',
	CURRENCY: 'currency',
	PAYOUT_TYPE: 'payoutType',
};

// Columns Reservations table
export const REPORTS_RESERVATIONS_BY_PLAYERS_COLUMNS = {
	ID: 'id',
	USER_NAME: 'userName',
	VERIFIED: 'verified',
	CREATED: 'created',
	AVAILABLE_FROM: 'availableFrom',
	PAID_OUT: 'paidOut',
	NAME: 'name',
	PAYOUT_TYPE: 'payoutType',
	BANK_ACCOUNT: 'bankAccount',
	RESERVED: 'reserved',
	BALANCE: 'balance',
	CURRENCY: 'currency',
};

//
export const REPORTS_CASH_FLOW_COLUMNS = {
	ACCOUNT: 'account',
	PAY_IN: 'payIn',
	PAY_OUT: 'payOut',
	PAY_DIFF: 'payDiff',
	DEP_IN: 'depIn',
	DEP_OUT: 'depOut',
	DEP_DIFF: 'depDiff',
	BALANCE: 'balance',
	PAY_IN_TAX: 'payInTax',
	PAY_OUT_TAX: 'payOutTax',
	IN: 'in',
	OUT: 'out',
	ST: 'st',
	TOTAL: 'total',
	CURRENCY: 'currency',
};

export const REPORTS_PROMOTIONS_COLUMNS = {
	ACCOUNT: 'account',
	TICKET_NUM: 'ticketNum',
	PROMO_CODE: 'promoCode',
	PROMO_TAG: 'promoTag',
	WALLET: 'wallet',
	usedPromo: 'usedPromo',
	PAY_IN: 'payIn',
	PAY_OUT: 'payOut',
	PAY_DIFF: 'payDiff',
	PROMO_NUM: 'promoNum',
	PROMO_SUM: 'promoSum',
	PAID_OUT_SUM: 'paidOutSum',
	EXPIRED: 'expired',
	EXPIRED_SUM: 'expiredSum',
	CURRENCY: 'currency',
};

export const REPORTS_STATISTICS_COLUMNS = {
	ACCOUNT: 'account',
	PAY_IN: 'payIn',
	LIVE_PAY_IN: 'livePayIn',
	WIN: 'win',
	NOT_PAIDOUT: 'notPaidout',
	DIFF: 'diff',
	MARGIN: 'margin',
	TICKETS_COUNT: 'ticketsCount',
	CURRENCY: 'currency',
};

export const REPORTS_PAYMENT_TRANSACTIONS_COLUMNS = {
	PROVIDER: 'provider',
	NUMBER_OF_PAYIN: 'numberOfPayin',
	NUMBER_OF_PAYOUT: 'numberOfPayout',
	PAYIN: 'payIn',
	PAYOUT: 'payOut',
};

export const INTERNET_USERS_COLUMNS = {
	ID: 'id',
	RISK_TAG: 'riskTag',
	EMAIL: 'email',
	POLITICIAN: 'politician',
	USERNAME: 'userName',
	FULL_NAME: 'fullName',
	LOCATION: 'location',
	CITY: 'city',
	DEP_IN_SUM: 'depInSum',
	DEP_OUT_SUM: 'depOutSum',
	DEP_DIFF: 'depDiff',
	BETTING_IN_SUM: 'bettingInSum',
	BETTING_OUT_SUM: 'bettingOutSum',
	BETTING_DIFF: 'bettingDiff',
	RESERVED_AMOUNT: 'reservedAmount',
	BALANCE: 'balance',
	GAMING_IN: 'gamingIn',
	GAMING_OUT: 'gamingOut',
	GAMING_DIFF: 'gamingDiff',
	BONUS: 'bonus',
	JACKPOT: 'jackpot',
	CURRENCY: 'currency',
};

export const INTERNET_TRANSACTIONS_COLUMNS = {
	ID: 'id',
	USER: 'user',
	AGENT: 'agent',
	CREATED_AT: 'createAT',
	ACCOUNT_TYPE: 'accountType',
	CURRENCY: 'currency',
	TYPE: 'type',
	SUB_TYPE: 'subType',
	PAYMENT_PROVIDER: 'paymentProvider',
	GAME_PROVIDER: 'gameProvider',
	APPLICATION: 'application',
	TICKET: 'ticket',
	AMOUNT_IN: 'amountIn',
	AMOUNT_OUT: 'amountOut',
	PREVIOUS_WALLET_BALANCE: 'previousWalletBalance',
};

export const REPORTS_TRANSACTIONS_COLUMNS = {
	ID_TRANSACTION: 'idTransaction',
	EXTERNAL_ID: 'externalId',
	ID_TICKET: 'idTicket',
	USER: 'user',
	AGENT: 'agent',
	DT_CREATED: 'dtCreated',
	ACCOUNT_TYPE: 'accountType',
	CURRENCY: 'currency',
	TRANSACTION_TYPE: 'transactionType',
	TRANSACTION_SUBTYPE: 'transactionSubtype',
	TRANSACTION_ISSUER: 'transactionIssuer',
	LOCATION_NAME: 'locationName',
	DEVICE_NAME: 'deviceName',
	GAME_PROVIDER: 'gameProvider',
	APPLICATION: 'application',
	PREVIOUS_WALLET_BALANCE: 'previousWalletBalance',
	WALLET_IN: 'walletIn',
	WALLET_OUT: 'walletOut',
};

export const REPORTS_ONLINE_REPORTS_COLUMNS = {
	COMPANY: 'company',
	REGION: 'region',
	GROUP: 'group',
	LOCATION: 'location',
	WALLET_TYPE: 'walletType',
	START_WALLET: 'startWallet',
	BET: 'bet',
	WIN: 'win',
	GAMING_BET: 'gamingBet',
	GAMING_WIN: 'gamingWin',
	GGR: 'ggr',
	DEPOSIT: 'deposit',
	WITHDRAWAL: 'withdrawal',
	BONUS_IN: 'bonusIn',
	BONUS_OUT: 'bonusOut',
	JACKPOT: 'jackpot',
	ERROR_IN: 'errorIn',
	ERROR_OUT: 'errorOut',
	CHANGES: 'changes',
	END_WALLET: 'endWallet',
	CURRENCY: 'currency',
};

export const AML_REPORTS_COLUMNS = {
	COMPANY_NAME: 'companyName',
	REGION_NAME: 'regionName',
	GROUP_NAME: 'groupName',
	LOCATION_NAME: 'locationName',
	ID_USER: 'idUser',
	USER_UID: 'userUid',
	DATE_PASSED: 'datePassed',
	EMAIL: 'email',
	POLITICALLY_ACTIVE: 'politicallyActive',
	PERIOD: 'period',
	AGGREAGATED_AMOUNT: 'aggregatedAmount',
	AML_AGGREGATION_TYPE: 'amlAggregationType',
	CURRENCY: 'currency',
};
