import React, { createContext, FC, useContext, useMemo, useState } from 'react';
import { Item } from '../types/general';
import {
	ADMINISTRATION_PROMOTION,
	ADMINISTRATION_PROMOTION_COLUMNS,
	INTERNET_TRANSACTIONS,
	INTERNET_TRANSACTIONS_COLUMNS,
	INTERNET_USERS,
	INTERNET_USERS_COLUMNS,
	REPORTS_CASH_FLOW,
	REPORTS_CASH_FLOW_COLUMNS,
	REPORTS_PROMOTIONS,
	REPORTS_PROMOTIONS_COLUMNS,
	REPORTS_EVENT_STATS,
	REPORTS_EVENT_STATS_COLUMNS,
	REPORTS_RESERVATIONS,
	REPORTS_RESERVATIONS_BY_PLAYERS,
	REPORTS_RESERVATIONS_BY_PLAYERS_COLUMNS,
	REPORTS_RESERVATIONS_COLUMNS,
	REPORTS_STATISTICS,
	REPORTS_STATISTICS_COLUMNS,
	REPORTS_TICKETS,
	REPORTS_TICKETS_COLUMNS,
	ONLINE_SUPPORT_USERS,
	ONLINE_SUPPORT_USERS_COLUMNS,
	REPORTS_PAYMENT_TRANSACTIONS,
	REPORTS_PAYMENT_TRANSACTIONS_COLUMNS,
	REPORTS_TICKETS_REPORT,
	REPORTS_TICKETS_REPORT_COLUMNS,
	REPORTS_TRANSACTIONS,
	REPORTS_TRANSACTIONS_COLUMNS,
	REPORTS_ONLINE_REPORTS,
	REPORTS_ONLINE_REPORTS_COLUMNS,
	AML_REPORTS,
	AML_REPORTS_COLUMNS,
} from '../consts/userConfig';
import { useTranslation } from 'react-i18next';

export interface UserConfigItem extends Item {
	visible: boolean;
}

export interface UserConfigItemStoredData {
	id: string;
	visible: boolean;
}

export interface UserConfigContextProps {
	tableName: string | undefined;
	columns: UserConfigItem[];
	visibleColumns: string[];
	isColumnVisible: (id: string) => boolean;
	setVisibleColumns: (id: string[]) => void;
	setActiveTable: (table: string) => void;
}

export interface UserConfigProviderProps {
	initTable: string;
}

const userConfigContextDefault: UserConfigContextProps = {
	tableName: undefined,
	columns: [],
	visibleColumns: [],
	isColumnVisible: () => true,
	setVisibleColumns: () => undefined,
	setActiveTable: () => undefined,
};

const UserConfigContext = createContext(userConfigContextDefault);

const UserConfigProvider: FC<UserConfigProviderProps> = ({ children, initTable }) => {
	const { t } = useTranslation();

	// default colums by table
	const getColumnsForTable = (forTable: string): UserConfigItemStoredData[] => {
		let tableColumns;
		if (forTable === ADMINISTRATION_PROMOTION) tableColumns = Object.values(ADMINISTRATION_PROMOTION_COLUMNS);
		if (forTable === REPORTS_TICKETS) tableColumns = Object.values(REPORTS_TICKETS_COLUMNS);
		if (forTable === REPORTS_TICKETS_REPORT) tableColumns = Object.values(REPORTS_TICKETS_REPORT_COLUMNS);
		if (forTable === REPORTS_EVENT_STATS) tableColumns = Object.values(REPORTS_EVENT_STATS_COLUMNS);
		if (forTable === REPORTS_RESERVATIONS) tableColumns = Object.values(REPORTS_RESERVATIONS_COLUMNS);
		if (forTable === REPORTS_RESERVATIONS_BY_PLAYERS)
			tableColumns = Object.values(REPORTS_RESERVATIONS_BY_PLAYERS_COLUMNS);
		if (forTable === REPORTS_CASH_FLOW) tableColumns = Object.values(REPORTS_CASH_FLOW_COLUMNS);
		if (forTable === REPORTS_PROMOTIONS) tableColumns = Object.values(REPORTS_PROMOTIONS_COLUMNS);
		if (forTable === REPORTS_STATISTICS) tableColumns = Object.values(REPORTS_STATISTICS_COLUMNS);
		if (forTable === REPORTS_PAYMENT_TRANSACTIONS) tableColumns = Object.values(REPORTS_PAYMENT_TRANSACTIONS_COLUMNS);
		if (forTable === REPORTS_ONLINE_REPORTS) tableColumns = Object.values(REPORTS_ONLINE_REPORTS_COLUMNS);
		if (forTable === INTERNET_TRANSACTIONS) tableColumns = Object.values(INTERNET_TRANSACTIONS_COLUMNS);
		if (forTable === INTERNET_USERS) tableColumns = Object.values(INTERNET_USERS_COLUMNS);
		if (forTable === ONLINE_SUPPORT_USERS) tableColumns = Object.values(ONLINE_SUPPORT_USERS_COLUMNS);
		if (forTable === REPORTS_TRANSACTIONS) tableColumns = Object.values(REPORTS_TRANSACTIONS_COLUMNS);
		if (forTable === AML_REPORTS) tableColumns = Object.values(AML_REPORTS_COLUMNS);

		return tableColumns ? tableColumns.map((id) => ({ id, visible: true })) : [];
	};

	// local storage helper functions
	const getStoredKey = (table: string): string => `UC_TABLE_${table}`;
	const getStoredData = (table: string, initValue: UserConfigItemStoredData[]): UserConfigItemStoredData[] => {
		let retVal = initValue;
		try {
			const value = window.localStorage.getItem(getStoredKey(table));
			if (value) {
				retVal = JSON.parse(value);
			}
		} catch {}

		return retVal;
	};
	const setStoredData = (table: string, value: UserConfigItemStoredData[]) => {
		window.localStorage.setItem(getStoredKey(table), JSON.stringify(value));
	};

	// columns configurations
	const getColumnsConfig = (forTable: string): UserConfigItem[] => {
		const columnsForTable = getColumnsForTable(forTable);
		const storedData = getStoredData(forTable, columnsForTable);

		const isVisibleFromSavedData = (id: string): boolean => {
			const retVal = storedData.find((data) => data.id === id);
			return retVal ? retVal.visible : true;
		};

		return columnsForTable.map(({ id }) => ({
			id,
			visible: isVisibleFromSavedData(id),
			name: t(`userConfig.tables.${forTable}.columns.${id}`),
		}));
	};

	// state variables
	const [tableName, setTableName] = useState(initTable);
	const [columns, setColumns] = useState(getColumnsConfig(initTable));

	const visibleColumns = useMemo(() => {
		const initalVisibleColumnsData = columns.filter(({ visible }) => visible).map(({ id }) => id);
		const addAll = initalVisibleColumnsData.length === getColumnsForTable(tableName).length;

		return [...(addAll ? ['all'] : []), ...initalVisibleColumnsData];
	}, [tableName, columns]);

	// user configuration context functions
	const isColumnVisible = (column: string) => visibleColumns.includes(column);

	const setVisibleColumns = (visible: string[]) => {
		const newColumns = columns.map((column) => ({
			...column,
			visible: visible.includes(column.id),
		}));
		setColumns(newColumns);
		setStoredData(
			tableName,
			newColumns.map(({ id, visible }) => ({ id, visible }))
		);
	};

	const setActiveTable = (tableName: string) => {
		setTableName(tableName);
		setColumns(getColumnsConfig(tableName));
	};

	return (
		<UserConfigContext.Provider
			value={{
				tableName,
				columns,
				visibleColumns,
				isColumnVisible,
				setVisibleColumns,
				setActiveTable,
			}}
		>
			{children}
		</UserConfigContext.Provider>
	);
};

const useUserConfig = () => useContext(UserConfigContext);

export { UserConfigProvider, useUserConfig };
