import { LiveMarketItem, LiveOdd } from './types';
import { MarketsMap } from '../../../types/markets';
import { sortByOrder } from '../../../utils/sorting/sortByOrder';
import { isEmpty } from 'lodash';
import { oddsExtrasReplacer } from '../../../utils/oddsExtrasReplacer';

export function separateMarkets(markets: LiveMarketItem[] = [], marketsMap: MarketsMap, sportId: string) {
	let separatedMarkets = [] as LiveMarketItem[];

	markets.forEach(({ id, odds, status }, index) => {
		// Find market data form map
		const market = marketsMap[sportId]?.markets?.markets?.[id];
		if (isEmpty(market)) return [];
		const { splitTypes, hasBoundaries, name, oddtypes, order } = market;

		// Return market as it is with added odds data from map
		if (!splitTypes && !hasBoundaries) {
			const fullOdds = odds.map((odd) => ({ ...odd, ...oddtypes[odd.id] }));
			const oddsWithoutExtras = fullOdds.filter((odd) => odd.extras === null);

			if (oddsWithoutExtras.length > 0) {
				separatedMarkets.push({
					id,
					key: `${id}:${index}`,
					odds: oddsWithoutExtras,
					status,
					name,
					splitTypes,
					hasBoundaries,
					order,
				});
			}

			// Make new market for each oddtype with players - extras
			const playerId = id + ':p';

			const fullOddsWithExtras = odds.map((odd) => ({ ...odd, ...oddtypes[playerId], id: odd.id }));

			const oddsWithExtras = fullOddsWithExtras.filter((odd) => odd.extras !== null);

			const oddsExtrasBoundaries = oddsWithExtras.filter((odd) => odd.extras?.boundary);
			const oddsExtrasNoBoundaries = oddsWithExtras.filter((odd) => odd.extras?.boundary === null);

			oddsExtrasNoBoundaries.forEach((odd, i) => {
				separatedMarkets.push({
					id,
					key: `${id}:${index}:${i}`,
					odds: [{ ...odd, name: odd.extras?.player }],
					status,
					name,
					order,
					splitTypes,
					hasBoundaries,
				});
			});

			let oddsExtrasBoundariesSplited = oddsExtrasBoundaries.map(({ id, ...rest }) => {
				const [oddId] = id.split('|');
				const oddMap = oddtypes[oddId];
				return { ...oddMap, id, ...rest };
			});
			const allBoundariesAreNumbers = oddsExtrasBoundariesSplited.every((odd) => !isNaN(+odd.extras.boundary));

			if (allBoundariesAreNumbers) {
				oddsExtrasBoundariesSplited.sort((a, b) => {
					if (a.extras.boundary > b.extras.boundary) return 1;
					if (a.extras.boundary < b.extras.boundary) return -1;
					return 0;
				});
			} else {
				const fullOddsWithSortKey = oddsExtrasBoundariesSplited.map((odd) => {
					const specialValue = odd.extras.boundary;
					const [home, away] = isNaN(+odd.extras)
						? specialValue.match(/\d-\d/)
							? specialValue.split('-')
							: specialValue.split(':')
						: [odd.specialValue, 0];
					return { ...odd, sortKey: +away * 100 + +home };
				});

				fullOddsWithSortKey.sort((a, b) => {
					if (a.sortKey > b.sortKey) return 1;
					if (a.sortKey < b.sortKey) return -1;
					return 0;
				});

				oddsExtrasBoundariesSplited = fullOddsWithSortKey.map(({ sortKey, ...rest }) => ({ ...rest }));
			}

			const oddsByBoundaries = oddsExtrasBoundariesSplited.sort(
				(a: any, b: any) => a.extras.boundary - b.extras.boundary
			);
			const oddSets = Object.values(oddsByBoundaries) as LiveOdd[];

			oddSets.forEach((odds, i) => {
				separatedMarkets.push({
					id,
					key: `${id}:${index}:${i}`,
					odds: [{ ...odds, name: odds.extras?.player }],
					status,
					order,
					name: `${name}|${odds.extras.boundary}`,
					splitTypes,
					hasBoundaries,
				});
			});
		}

		// Make new market for each oddtype on it, also construct second oddtype as no win
		if (splitTypes) {
			const fullOdds = odds.map((odd) => ({ ...odd, ...oddtypes[odd.id] }));
			sortByOrder(fullOdds);

			fullOdds.forEach((odd, i) => {
				separatedMarkets.push({
					id,
					key: `${id}:${index}:${i}`,
					odds: [{ ...odd }, { ...odd, name: 'NO WIN', value: 1, result: null }],
					status,
					name: hasBoundaries && odd.specialValue ? `${name}|${odd.specialValue}` : name,
					order,
					splitTypes,
					hasBoundaries,
				});
			});
		}

		// Separate oddtypes by boundaries and form each set make new market
		if (hasBoundaries && !splitTypes) {
			let fullOdds = odds.map(({ id, ...rest }) => {
				const [oddId] = id.split('|');
				const oddMap = oddtypes[oddId];

				return { ...oddMap, id, ...rest };
			});

			const allBoundariesAreNumbers = fullOdds.every((odd) => !isNaN(+odd.specialValue));

			if (allBoundariesAreNumbers) {
				fullOdds.sort((a, b) => {
					if (a.specialValue > b.specialValue) return 1;
					if (a.specialValue < b.specialValue) return -1;
					return 0;
				});
			} else {
				const fullOddsWithSortKey = fullOdds.map((odd) => {
					const { specialValue } = odd;
					const [home, away] = isNaN(+odd.specialValue)
						? specialValue.match(/\d-\d/)
							? specialValue.split('-')
							: specialValue.split(':')
						: [odd.specialValue, 0];
					return { ...odd, sortKey: +away * 100 + +home };
				});

				fullOddsWithSortKey.sort((a, b) => {
					if (a.sortKey > b.sortKey) return 1;
					if (a.sortKey < b.sortKey) return -1;
					return 0;
				});

				fullOdds = fullOddsWithSortKey.map(({ sortKey, ...rest }) => ({ ...rest }));
			}

			const oddsByBoundaries = fullOdds.reduce((acc, current) => {
				acc[current.specialValue] = acc[current.specialValue] ? [...acc[current.specialValue], current] : [current];
				return acc;
			}, {});

			const oddSets = Object.values(oddsByBoundaries) as [LiveOdd[]];
			oddSets.forEach((odds, i) => {
				const _odds = odds.map((odd) => ({
					...odd,
					description: oddsExtrasReplacer(odd.description ? odd.description : '', odd.extras),
					name: oddsExtrasReplacer(odd.name ? odd.name : '', odd.extras),
				}));

				let _name = oddsExtrasReplacer(name, fullOdds[i].extras) || '';

				if ((fullOdds[i].extras && fullOdds[i].description === 'under') || fullOdds[i].description === 'over') {
					_name = `${_name} ${odds[0].extras?.player ?? ''}`;
				}

				const specialValue = odds[0].extras?.boundary ?? odds[0].specialValue ?? '';

				separatedMarkets.push({
					id,
					key: `${id}:${index}:${i}`,
					odds: _odds,
					status,
					order,
					name: `${_name}|${specialValue}`,
					splitTypes,
					hasBoundaries,
				});
			});
		}
	});

	return separatedMarkets || [];
}

export const sortOn = <T>(prop: string, list: string[]) => {
	const order = list.reduce((obj, key, idx) => Object.assign(obj, { [key]: idx + 1 }), {});
	const getVal = (item: T) => order[item[prop]] || Infinity;

	return (a: T, b: T) => getVal(a) - getVal(b);
};

interface Styles {
	[key: string]: React.CSSProperties;
}
export const styles: Styles = {
	form: {
		justifyContent: 'flex-start',
		width: 'auto',
		marginRight: '20px',
	},
	storno: {
		justifyContent: 'flex-start',
		width: 'auto',
		marginRight: '0px',
		padding: '0px',
	},
};
