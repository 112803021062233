import React, { FC, useState, useEffect, ChangeEvent } from 'react';
import InPortal from '../../../InPortal';
import i18n from '../../../../../localisation/i18n';
import { useTicket } from '../../context/TicketContext';
import { useTranslation } from 'react-i18next';
import { FullOdd } from '../../types';
import { toFixed } from '../../../../../utils/toFixed';
import { useKeyboardListener } from '../../../../../hooks/useKeyboardListener';
import { oddsExtrasReplacer } from '../../../../../utils/oddsExtrasReplacer';

interface Props {
	odd: FullOdd;
}

export const EditOdd: FC<Props> = ({ odd }) => {
	const { t } = useTranslation();
	const { selectOdd, updateOdd, selectedOdd } = useTicket();

	const [selectedOption, selectOption] = useState<number | null>(null);
	const [changedQuota, setChangedQuota] = useState('');
	const [error, setError] = useState('');

	useEffect(() => {
		selectOption(odd.result);
		setChangedQuota(`${toFixed(odd.value)}`);
	}, []);

	const handleSelect = (id: number | null) => {
		selectOption(id);
		setError('');
	};

	const handleQuotaChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;

		const [_, decimals] = value.split('.');
		if (decimals?.length > 2) return;
		if (value && +value < 1) return setError('Odd must be greater than 1');

		setChangedQuota(value);
		setError('');
	};

	const saveChanges = () => {
		const newValue = selectedOption === -1 ? 1.0 : changedQuota ? +changedQuota : odd.value;
		updateOdd(selectedOption, newValue);
		selectOdd(null);
	};
	useKeyboardListener(selectedOdd ? 'Escape' : '', () => selectOdd(null));
	return (
		<InPortal>
			<div className={`modal_cover sm over_1 toggle`}>
				<div className="modal_content fadeInDown animated faster">
					<button className="btn transparent close" onClick={() => selectOdd(null)}>
						<i className="mdi mdi-close"></i>
					</button>

					<div className="modal_head">
						<h2 className="modal_title padding_left_30">
							<strong className="padding_right_10 uppercase">{oddsExtrasReplacer(odd.name, odd.extras)}</strong>{' '}
							{t('general.edit')}
						</h2>
					</div>
					{/* Body */}
					<div className="modal_body align_center direction_column padding_30">
						{oddResultStatuses.map(({ id, name }) => (
							<div key={id} className="radio_custom tooltip_right" onClick={() => handleSelect(id)}>
								<input type="radio" name={name} checked={selectedOption === id} onChange={() => {}} />
								<i className="mdi mdi-radiobox-blank unchecked"></i>
								<i className="mdi mdi-radiobox-marked checked"></i>
								<label className="right">{name}</label>
							</div>
						))}

						<div className="form_row">
							<label htmlFor="stake_edit">{t('reports.tickets.changedQuota')}</label>
							<input
								className="stake_edit normal short_80"
								type="number"
								value={changedQuota}
								onChange={handleQuotaChange}
								disabled={selectedOption === -1}
							/>
						</div>
						<div className="form_row">
							<label style={{ color: '#e35454' }}>{error}</label>
							{selectedOption === -1 ? <label>{'Odd value will be set to 1.00'}</label> : null}
						</div>
					</div>

					<div className="modal_footer col_border_top">
						<button className="btn md round outline" onClick={() => selectOdd(null)}>
							{t('general.close')}
						</button>
						<button className="btn md round green" onClick={saveChanges}>
							{t('general.save')}
						</button>
					</div>
				</div>
			</div>
		</InPortal>
	);
};

const oddResultStatuses = [
	{ id: 1, name: i18n.t('reports.tickets.winning') },
	{ id: 0, name: i18n.t('reports.tickets.losing') },
	{ id: -1, name: i18n.t('reports.tickets.storno') },
	{ id: null, name: i18n.t('reports.tickets.onWait') },
];
