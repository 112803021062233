import React, { FC, useState, useEffect, useRef } from 'react';
import api from '../../../api';
import { usePagination } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import { SimpleLoader, useLoaded, Loader } from '../../../components/general/Loader';
import { Header, PageContentWrapper, PageFooter } from '../../../components/layout';
import { FooterDropdown } from '../../risk-management/controller/components/general';
import { Height } from '../../administration/live/components/Height';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { GeoFilters } from '../../reports/tickets/components/GeoFilters';
import { InputFilter } from '../../reports/tickets/components/InputFilter';
import { OnlineSupportUser } from './types';
import { TableHeader } from './components/TableHeader';
import { TableRow } from './components/TableRow';
import Dropdown from '../../../components/general/Dropdown';
import { useAxios } from '../../../hooks/useAxios';
import { Currency } from '../../administration/currencies/types';
import { useNotify } from '../../../hooks/useNotifications';
import { CSVLink } from 'react-csv';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';
import getErrorText from '../../../utils/getErrorMsg';
import { DateRange } from '../../../components/Dates/DateRange';
import { useData } from '../../../context/DataProvider';
import { utcToLocal } from '../../../utils/getDate';
import MultiSelectDropdown from '../../../components/general/MultiSelectDropdown';
import { UserConfigProvider, useUserConfig } from '../../../context/UserConfigProvider';
import { ONLINE_SUPPORT_USERS } from '../../../consts/userConfig';
import { wrapComponent } from '../../../utils/wrapComponent';
import { Switch } from '../../../components/general/Switch';

const OnlineSupportUsers: FC = () => {
	const { t } = useTranslation();

	const { cityTimeZone } = useData();

	const { columns, visibleColumns, setVisibleColumns } = useUserConfig();

	useDocumentTitle(t('onlineSupport.users.title'));

	const { error } = useNotify();

	const [loading, setLoading] = useState(false);

	const [filterOpen, setFilterOpen] = useState(false);

	const [timeZone, setTimeZone] = useState('Europe/Belgrade');

	const toggleFilter = () => {
		setFilterOpen((filter) => !filter);
	};

	const [users, setUsers] = useState<OnlineSupportUser[]>([]);

	const { data: currencies } = useAxios<Currency[]>({
		apiFunction: async function (): Promise<Currency[]> {
			try {
				return await api.currencies.getCurrencies();
			} catch (err) {
				error(getErrorText(err) || 'toast.error.defaultText');
			}

			return currencies;
		},
		initialData: [],
		dependencies: [],
	});

	// Filters
	const [orderBy, setOrderBy] = useState('');
	const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
	const [selectedCurency, setSelectedCurency] = useState<string | null>(null);
	const [isPolitician, setIsPolitician] = useState(false);

	// Pagination
	const pagination = usePagination(50);
	const { from, setTotal, setPerPage, perPage, total } = pagination;

	const [idUser, setIdUser] = useState('');
	const [email, setEmail] = useState('');
	const [nationalId, setNationalId] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [telephone, setTelephone] = useState('');
	const [locationName, setLocationName] = useState('');
	const [city, setCity] = useState('');
	const [universalSearch, setUniversalSearch] = useState('');

	const [resMinAmount, setResMinAmount] = useState('');
	const [resMaxAmount, setResMaxAmount] = useState('');

	const [onlineMinAmount, setOnlineMinAmount] = useState('');
	const [onlineMaxAmount, setOnlineMaxAmount] = useState('');

	const [fbMinAmount, setFbMinAmount] = useState('');
	const [fbMaxAmount, setFbMaxAmount] = useState('');

	const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(null);

	// Export
	const [exportData, setExportData] = useState('');
	const [fileName, setFileName] = useState('');
	const excelRef = useRef<any>();

	const [verified, setVerified] = useState<string | null>(null);

	const getOnlineUsersOnKey = () => {
		getOnlineUsers();
		setFilterOpen(false);
	};

	async function getOnlineUsers(isExport = false) {
		setExportData('');
		setLoading(true);
		const locations = selectedLocations.filter((location) => location !== 'all');
		const payload = {
			sortKey: orderBy || null,
			idUser: +idUser || null,
			uuidUser: null,
			email: email || null,
			nationalId,
			firstName,
			lastName,
			telephone,
			locationName,
			city,
			idLocation: locations.length ? locations : null,
			reservation: {
				minAmount: +resMinAmount,
				maxAmount: +resMaxAmount,
			},
			onlineBalance: {
				minAmount: +onlineMinAmount,
				maxAmount: +onlineMaxAmount,
			},
			freeBetBalance: {
				minAmount: +fbMinAmount,
				maxAmount: +fbMaxAmount,
			},
			created: {
				fromValue: startDate ? utcToLocal(startDate).toISOString() : null,
				toValue: endDate ? utcToLocal(endDate).toISOString() : null,
			},
			timezone: timeZone,
			currency: selectedCurency || null,
			verification_status: verified?.replace(/ /g, '_') || null,
			limit: perPage,
			skip: from,
			q: universalSearch,
			isPolitician,
		};

		try {
			if (isExport) {
				try {
					const [fileName, data] = await api.support.exportUsers(payload);
					setExportData(data.replace());
					setFileName(fileName);
					excelRef?.current?.link?.click();
				} catch (err) {
					error(getErrorText(err) || 'toast.error.defaultText');
				} finally {
					setExportData('');
					setFileName('');
				}
			} else {
				const { count, users } = await api.support.getUsers(payload);
				setUsers(users);
				setTotal(count);
			}
		} catch (err) {
			error(getErrorText(err) || 'toast.error.defaultText');
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		getOnlineUsers();
	}, [from, perPage, orderBy]);

	const isMobileView = useCheckMobileScreen();

	const loaded = useLoaded([users], true);

	return (
		<Loader loaded={loaded}>
			<Header
				widthClass={'w_1800'}
				hideLogo
				leftTool={
					<>
						<span
							className="tool_item tooltip_right margin_right_10 mobile_de_dumb_toggle_trigger"
							onClick={toggleFilter}
						>
							<i className="mdi mdi-tune"></i>
							<span className="tooltip_content">{t('reports.tickets.toggleFilter')}</span>
						</span>

						<GeoFilters
							selectedLocations={selectedLocations}
							selectLocations={setSelectedLocations}
							instanceType="ONLINE"
							direction="down"
						/>
					</>
				}
				rightTool={
					<>
						<Dropdown
							items={[
								{ id: '', name: t('general.clearSelected') },
								...currencies.map(({ code }) => ({ id: code, name: code })),
							]}
							selectedItem={selectedCurency || ''}
							selectItem={(id) => {
								setSelectedCurency(id);
							}}
							margins={['0', '0', '0', '0']}
							icon={'mdi mdi-menu-down'}
							fixedTitle={selectedCurency || t('general.selectCurrency')}
						/>
						<Dropdown
							items={[
								{ id: '', name: t('general.clearSelected') },
								...[
									{ id: 'VERIFIED', name: t('general.verified') },
									{ id: 'HAS DOCUMENTS', name: t('general.hasDocument') },
									{ id: 'EMAIL VERIFIED', name: t('general.emailVerified') },
									{ id: 'REGISTERED', name: t('general.registered') },
								],
							]}
							selectedItem={verified || ''}
							selectItem={setVerified}
							margins={['0', '30', '0', '30']}
							icon={'mdi mdi-menu-down'}
							fixedTitle={verified || t('general.userStatus')}
						/>
						<div className="form_col">
							<InputFilter
								type="email"
								value={email}
								setValue={setEmail}
								onEnter={getOnlineUsersOnKey}
								placeholder={t('general.email')}
								marginRight={10}
							/>
						</div>
						<div className="form_col">
							<InputFilter
								type="text"
								value={locationName}
								setValue={setLocationName}
								onEnter={getOnlineUsersOnKey}
								placeholder={t('onlineSupport.users.locationName')}
								marginRight={10}
							/>
						</div>
						<div className="form_col">
							<InputFilter
								type="number"
								value={idUser}
								setValue={setIdUser}
								onEnter={getOnlineUsersOnKey}
								placeholder={t('general.userId')}
								marginRight={10}
							/>
						</div>
						<div className="form_col">
							<InputFilter
								type="text"
								value={nationalId}
								setValue={setNationalId}
								onEnter={getOnlineUsersOnKey}
								placeholder={t('general.jmbg')}
								marginRight={10}
							/>
						</div>
						<div className="form_col">
							<InputFilter
								type="text"
								value={universalSearch}
								setValue={setUniversalSearch}
								onEnter={getOnlineUsersOnKey}
								placeholder={t('general.universalSearch')}
								marginRight={10}
							/>
						</div>
						<button
							className={`btn lg_32 blue ${loading ? 'disabled' : ''} `}
							onClick={() => getOnlineUsers(true)}
						>
							{t('general.export')}
						</button>
						{exportData ? (
							<CSVLink
								data={csvToArray(exportData)}
								filename={fileName}
								ref={excelRef}
								className={`hiddenExcel`}
								target="_blank"
							></CSVLink>
						) : null}
						{!filterOpen ? (
							<button
								disabled={loading}
								className={`btn lg_32 blue ${loading ? 'disabled' : ''} `}
								onClick={() => getOnlineUsers()}
							>
								{t('general.filter')}
							</button>
						) : null}
						<MultiSelectDropdown
							title={t('general.display')}
							options={columns}
							selectedOptions={visibleColumns}
							selectOption={setVisibleColumns}
							direction={'right_0'}
						/>
					</>
				}
			></Header>
			<div className={`mobile_de_filter ${filterOpen ? 'toggle' : ''} ${isMobileView ? 'w_1800 static' : ''}`}>
				<div className="filter_section col-2 padding_30">
					<InputFilter
						type="number"
						value={resMinAmount}
						setValue={setResMinAmount}
						marginBottom={15}
						label={t('onlineSupport.users.minReservation')}
						onEnter={getOnlineUsersOnKey}
					/>
					<InputFilter
						type="number"
						value={resMaxAmount}
						setValue={setResMaxAmount}
						label={t('onlineSupport.users.maxReservation')}
						onEnter={getOnlineUsersOnKey}
					/>
				</div>
				<div className="filter_section col-2 padding_30">
					<InputFilter
						type="number"
						value={onlineMinAmount}
						setValue={setOnlineMinAmount}
						marginBottom={15}
						label={t('onlineSupport.users.minOnline')}
						onEnter={getOnlineUsersOnKey}
					/>
					<InputFilter
						type="number"
						value={onlineMaxAmount}
						setValue={setOnlineMaxAmount}
						label={t('onlineSupport.users.maxOnline')}
						onEnter={getOnlineUsersOnKey}
					/>
				</div>
				<div className="filter_section col-2 padding_30">
					<InputFilter
						type="number"
						value={fbMinAmount}
						setValue={setFbMinAmount}
						marginBottom={15}
						label={t('onlineSupport.users.minFb')}
						onEnter={getOnlineUsersOnKey}
					/>
					<InputFilter
						type="number"
						value={fbMaxAmount}
						setValue={setFbMaxAmount}
						label={t('onlineSupport.users.maxFb')}
						onEnter={getOnlineUsersOnKey}
					/>
				</div>
				<div className="filter_section col-2 padding_30">
					<InputFilter
						type="text"
						value={firstName}
						setValue={setFirstName}
						marginBottom={15}
						label={t('general.firstName')}
						onEnter={getOnlineUsersOnKey}
					/>
					<InputFilter
						type="text"
						value={lastName}
						setValue={setLastName}
						label={t('general.lastName')}
						onEnter={getOnlineUsersOnKey}
					/>
				</div>
				<div className="filter_section col-2 padding_30">
					<InputFilter
						type="text"
						value={city}
						setValue={setCity}
						marginBottom={15}
						label={t('general.city')}
						onEnter={getOnlineUsersOnKey}
					/>
					<InputFilter
						type="text"
						value={telephone}
						setValue={setTelephone}
						label={t('general.telephone')}
						onEnter={getOnlineUsersOnKey}
					/>
				</div>
				<div className="filter_section col-2 padding_30 date_time_container">
					<div
						className={`margin_bottom_${15} margin_right_${0} padding_mobile margin_top_20`}
						style={{ border: 'none' }}
					>
						<DateRange
							endDate={endDate}
							startDate={startDate}
							maxDate={new Date()}
							setEndDate={setEndDate}
							setStartDate={setStartDate}
							datePickerClassName={'min_width_50'}
						/>
					</div>

					<div className={`form_col margin_right_${0} padding_mobile margin_top_30`}>
						<Dropdown
							items={cityTimeZone.map(({ id, name }) => ({ id: `${id}`, name }))}
							selectItem={setTimeZone}
							selectedItem={timeZone}
							margins={['0', '0', '0', '4']}
							icon={'mdi mdi-menu-down'}
							withSearch
						/>
					</div>
				</div>

				<div className="filter_section col-2 padding_30">
					<div className={`form_col margin_right_${0} padding_mobile`}>
						<div className="font_12px font_600 margin_bottom_5">{t('general.allUsers')}</div>
						<Switch checked={isPolitician} toggle={setIsPolitician} name="" onLabel="" offLabel="" />
						<div className="font_12px font_600 margin_top_5">{t('general.politicallyActiveUsers')}</div>
					</div>

					<button
						onClick={() => {
							getOnlineUsers();
							setFilterOpen(false);
						}}
						className="btn lg blue full_w margin_top_30"
					>
						{t('general.filter')}
					</button>
				</div>
			</div>

			<PageContentWrapper isMobileView={isMobileView} widthClass={'w_1800'} overflow_x_scroll>
				{!loading ? (
					<table className="admin_table autorisation full_w sticky_header w_1800">
						<TableHeader selectedSort={orderBy} selectSort={setOrderBy} />
						<tbody className="t_body">
							{users.map((user) => (
								<TableRow key={user.idUser} {...user} />
							))}
						</tbody>
					</table>
				) : (
					<Height>
						<SimpleLoader />
					</Height>
				)}
			</PageContentWrapper>

			<PageFooter
				numberOfItems={<FooterDropdown perPage={perPage} setPerPage={setPerPage} />}
				{...pagination}
				total={total}
			></PageFooter>
		</Loader>
	);
};

export function csvToArray(text: string) {
	let p = '',
		row = [''],
		ret = [row],
		i = 0,
		r = 0,
		s = !0,
		l;
	for (l of text) {
		if ('"' === l) {
			if (s && l === p) row[i] += l;
			s = !s;
		} else if (',' === l && s) l = row[++i] = '';
		else if ('\n' === l && s) {
			if ('\r' === p) row[i] = row[i].slice(0, -1);
			row = ret[++r] = [(l = '')];
			i = 0;
		} else row[i] += l;
		p = l;
	}
	return ret;
}

const UserConfigProviderForSupportUsers: FC = ({ children }) => {
	return <UserConfigProvider initTable={ONLINE_SUPPORT_USERS}>{children}</UserConfigProvider>;
};

export default wrapComponent<typeof OnlineSupportUsers>(OnlineSupportUsers, UserConfigProviderForSupportUsers);
