import { axiosInstance } from './../config';
import { categories } from './categories';
import { tags } from './tags';

interface Params {
	requestedDatetimeFrom: Date;
	authorisedDatetimeFrom?: Date;
	authorisedDatetimeTo?: Date;
	authStatusFilters?: string[] | null;
	statusFilters?: string[];
	ticketType?: string[];
	locationIds?: string[];
	sortBy?: string[];
	offset: number;
	limit: number;
}
export interface TicketHistory {
	offerPlanList: (string | undefined)[] | null;
	listCode: number | null;
	dtRequestedFrom: Date | string | null;
	dtRequestedTo: Date | string | null;
	bookmaker: string;
	username: string;
	idLocations: string[] | null;
	ticketType: string[];
	statuses: string[] | null;
	payInChannel: string[];
	accountType: string[];
	idSports: string[] | null;
	idTournaments: string[] | null;
	idOddtypes: string[] | null;
	minEvents: number | null;
	maxEvents: number | null;
	idBarcode: string;
	minWaitsFor: number | null;
	maxWaitsFor: number | null;
	minAmount: number | null;
	maxAmount: number | null;
	minGain: number | null;
	orderBy: string[];
	offset: number;
	limit: number;
	statusesOperator?: string;
}
export interface UpdateTag {
	riskTagCode: string;
	userUuid: string;
}

export const risk = {
	getTickets(params: Params) {
		return axiosInstance.post(`/risk/tickets`, params).then(({ data }) => data);
	},
	getTicketsHistory(params: TicketHistory) {
		return axiosInstance.post(`/risk/tickets_history`, params).then(({ data }) => data);
	},

	getTicketsHistorySum(params: TicketHistory) {
		return axiosInstance.post('/risk/tickets_history_summaries', params).then(({ data }) => data);
	},

	getTicket(id: string) {
		return axiosInstance(`/risk/tickets/${id}`).then((res) => res.data);
	},
	getEventsAdditionalData(eventIds: string[]) {
		return axiosInstance
			.get(`/risk/events`, {
				params: {
					eventIds,
				},
			})
			.then((res) => res.data);
	},

	getMappedMarkets() {
		return axiosInstance.get(`administration/markets/structured/`).then((res) => res.data);
	},
	...categories,
	...tags,
};
