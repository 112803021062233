import { axiosInstance } from './../config';
import { OnlineUser } from '../../pages/online-user/types';
import { UserType, UsersSumType } from '../../pages/internet/users/types';
import { PeriodSum } from '../../pages/online-user/components/tickets/types';
import { UserBonuses } from '../../pages/online-user/components/bonuses/types';
import { LockedDepositResponse, Reservation } from '../../pages/online-user/components/wallets/types';
import { VerificationData, VerificationUpload } from '../../pages/online-user/components/documents/types';
import { LimitsData, UpadateLimitsData } from '../../pages/online-user/components/limits/types';
import { fileNameFromHeaders } from '../../utils/getExportFileName';

interface TicketSummaryParams {
	user_uuid: string;
	querySummaryPeriods: string[];
}

export interface CreateReservationPayload {
	userUuid: string;
	idLocation: string;
	instanceType: 'LANDBASE' | 'ONLINE';
	reservedAmount?: number;
	dtAvailable: string;
	dtExpires: string;
	reservationPayoutType: 'BANK' | 'LOCATION';
}

export const users = {
	getUsers(params: UserType, version?: string) {
		return axiosInstance.post(`/administration/users/${version ?? ' '}`, params).then((res) => res.data);
	},
	exportUsersAndSums(params: UsersSumType) {
		return axiosInstance.post('/administration/users/export_user_and_sums', params).then((res) => {
			return [fileNameFromHeaders(res.headers, 'cash-flow'), res.data];
		});
	},
	getUser(id: number) {
		return axiosInstance.get(`/administration/users/${id}`).then((res) => res.data);
	},
	getUserByEmail(email: string): Promise<OnlineUser> {
		return axiosInstance
			.get(`/administration/users/user/?email=${encodeURIComponent(email)}`)
			.then((res) => res.data);
	},

	getUserInfo(uid: string): Promise<OnlineUser> {
		return axiosInstance.get(`/administration/users/user/full_info?userUUID=${uid}`).then((res) => res.data);
	},

	updateUser(uid: string, user: any): Promise<OnlineUser> {
		return axiosInstance.put(`/administration/users/${uid}`, user).then((res) => res.data);
	},

	updateUserPoliticallyData(uid: string, data: any): Promise<OnlineUser> {
		return axiosInstance.put(`/administration/users/politically_active/${uid}`, data).then((res) => res.data);
	},


	updateSelfBanned(uid: string, self_banned: boolean) {
		return axiosInstance
			.put(`/administration/users/self_banned_data/${uid}?self_banned=${self_banned}`)
			.then((res) => res.data);
	},

	sendActivation(uid: string) {
		return axiosInstance
			.post(`/administration/users/user/email_activation_token?userUUID=${uid}`)
			.then((res) => res.data);
	},

	sendPasswordReset(uid: string) {
		return axiosInstance
			.post(`/administration/users/user/reset_password_token?userUUID=${uid}`)
			.then((res) => res.data);
	},

	verifyAccount(uid: string) {
		return axiosInstance.post(`/administration/users/user/account/verify?userUUID=${uid}`).then((res) => res.data);
	},

	logout(uid: string) {
		return axiosInstance
			.get(`/administration/users/user/delete_user_session?userUUID=${uid}`)
			.then((res) => res.data);
	},

	getTicketSummary(params: TicketSummaryParams): Promise<PeriodSum[]> {
		return axiosInstance
			.get('/administration/users/user/ticket_summary', { params })
			.then((res) => res.data)
			.then((data) => data.periods);
	},

	getWalletData(uid: string) {
		return axiosInstance.get(`/administration/users/user/wallet_info/${uid}`).then((res) => res.data);
	},

	updateReservationInfo(payload: { userUuid: string; dtAvailable: string }): Promise<Reservation> {
		return axiosInstance.post('/administration/users/user/reservation_info', payload).then((res) => res.data);
	},
	manualReservationPayout(userUuid: string) {
		return axiosInstance.post(`/administration/users/user/payout_reservation/${userUuid}`).then((res) => res.data);
	},

	createReservation(payload: CreateReservationPayload): Promise<Reservation> {
		return axiosInstance.post('/administration/users/user/reservation', payload).then((res) => res.data);
	},

	cancelReservation(uid: string) {
		return axiosInstance.post(`/administration/users/user/cancel_reservation/${uid}`).then((res) => res.data);
	},

	//Bonuses
	getBonuses(uid: string): Promise<any> {
		return axiosInstance.get(`/administration/users/${uid}/bonuses`).then((res) => res.data);
	},

	updateEmail(uid: string, newEmail: string): Promise<any> {
		return axiosInstance
			.post(`/administration/users/user/change_email?userUUID=${uid}&newEmail=${newEmail}`)
			.then((res) => res.data);
	},

	unlockBonus(uid: string, idBonus: number): Promise<UserBonuses> {
		return axiosInstance.post(`/administration/users/${uid}/unlock_bonus/${idBonus}`).then((res) => res.data);
	},
	createDepositWithdraw(payload: any): Promise<any> {
		return axiosInstance.post('/administration/transactions/user_transaction/', payload).then((res) => res.data);
	},

	getVerificationData(uuid: string): Promise<VerificationData> {
		return axiosInstance
			.get(`/administration/users/user/user_verification_data?userUUID=${uuid}`)
			.then((res) => res.data);
	},

	uploadVerificationData(uuid: string, uploadData: VerificationUpload): Promise<VerificationData> {
		const { userIdImage, userIdBackImage, front_facing_image } = uploadData;
		if (!userIdImage || !userIdBackImage || !front_facing_image)
			return Promise.reject('Verification images not provided!');
		const bodyFormData = new FormData();
		bodyFormData.append('userIdImage', userIdImage);
		bodyFormData.append('userIdBackImage', userIdBackImage);
		bodyFormData.append('front_facing_image', front_facing_image);

		return axiosInstance
			.post(`/administration/users/user/user_verification_data`, bodyFormData, {
				params: {
					user_uuid: uuid,
				},
				headers: {
					Accept: 'application/json',
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((res) => res.data);
	},

	deleteVerificationData(uuid: string) {
		return axiosInstance
			.delete(`/administration/users/user/user_verification_data?userUUID=${uuid}`)
			.then((res) => res.data);
	},

	// Deposit
	unlockDeposit(uuid: string, depositId: number): Promise<LockedDepositResponse> {
		return axiosInstance
			.get(`/administration/users/${uuid}/unlock_deposit/{id_locked_deposit}?idUserDeposit=${depositId}`)
			.then((res) => res.data);
	},

	//Limits

	getLimitsData(uid: string) {
		return axiosInstance.get(`/administration/users/user/limits?id_user=${uid}`).then((res) => res.data);
	},

	updateLimitsData(code: number, data: UpadateLimitsData) {
		return axiosInstance.put(`/administration/users/user/limits?id_user=${code}`, data).then((res) => res.data);
	},

	deleteLimitsData(code: string, periodInDays: number, type: string): Promise<LimitsData> {
		return axiosInstance
			.delete(`/administration/users/user/limits?id_user=${code}`, {
				data: { periodInDays, type },
			})
			.then((res) => res.data);
	},
};
