import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import settings from '../settings.json';
import trans from './resources/index';

settings.translationApi = `${settings.host}/localisation/lang/{{lng}}`;

const fallbackLng = ['sr'];
export const availableLanguages = ['en', 'sr', 'de', 'ru'];

i18n
	.use(Backend) // load translation using xhr
	.use(LanguageDetector) // detect user language
	.use(initReactI18next) // pass the i18n instance to react-i18next.
	.init({
		fallbackLng, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
		// debug: true,
		whitelist: availableLanguages,
		resources: trans,
		// backend: {
		// 	loadPath: settings.translationApi
		// },

		detection: {
			order: ['localStorage', 'navigator', 'cookie'],
			lookupCookie: 'i18nextLng',
			lookupLocalStorage: 'i18nextLng',
			caches: ['localStorage'],
		},

		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
