import React, { useContext, useEffect, useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import components
import SidebarLink from './components/SidebarLink';
import SidebarDropdown from './components/SidebarDropdown';
import SidebarButton from './components/SidebarButton';

// Import contexts
import { LayoutContext } from '../LayoutContext';
import { useUser } from '../../../context/UserProvider';
import AdministrationDropdown from './components/AdministrationDropdown';
import PairingDropdown from './components/PairingDropdown';
import { DropdownWrapper } from './components/DropdownWrapper';
import api from '../../../api';

export interface DateFormat {
	hrsOfsset: number;
	hrsSign: string;
	dateHours: string;
	dateMinutes: string;
	dateSecunds: string;
	dateDay: string;
	dateMonth: string;
	dateYear: number;
}

const formateDate = (date: Date): DateFormat => {
	return {
		hrsOfsset: Math.floor(Math.abs(date.getTimezoneOffset()) / 60),
		hrsSign: date.getTimezoneOffset() > 0 ? '-' : '+',
		dateHours: date.getHours().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }),
		dateMinutes: date.getMinutes().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }),
		dateSecunds: date.getSeconds().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }),
		dateDay: date.getDate().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }),
		dateMonth: (date.getMonth() + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }),
		dateYear: date.getFullYear(),
	};
};

type DateProps = { sidebarToggled: boolean };

const DateComponent: React.FC<DateProps> = ({ sidebarToggled }) => {
	//BO Clock
	const [formattedDate, setformattedDate] = useState<DateFormat>(formateDate(new Date()));

	useEffect(() => {
		const timer = setTimeout(() => {
			setformattedDate(formateDate(new Date()));
		}, 1000);
		return () => clearTimeout(timer);
	}, [formattedDate]);

	return sidebarToggled ? (
		<div className="clock">
			<span className="short">{`${formattedDate.dateHours}:${formattedDate.dateMinutes}:${formattedDate.dateSecunds}`}</span>
		</div>
	) : (
		<div className="clock">
			<div>
				<i className="mdi mdi-clock" />
				<span className="clock-short">{`${formattedDate.dateHours}:${formattedDate.dateMinutes}:${formattedDate.dateSecunds} UTC ${formattedDate.hrsSign}${formattedDate.hrsOfsset}`}</span>
			</div>
			<div className="clock-long">
				<i className="mdi mdi-calendar-check" />
				<span className="">{`${formattedDate.dateDay}.${formattedDate.dateMonth}.${formattedDate.dateYear}.`}</span>
			</div>
		</div>
	);
};

const Sidebar = () => {
	const { t } = useTranslation();
	const { sidebarToggled, toggleSidebar, sidebarOverContent } = useContext(LayoutContext);
	const coverContent = sidebarOverContent ? 'app_sidebar cover_content' : 'app_sidebar';
	const sidebarClass = sidebarToggled ? `${coverContent} menu_hide` : coverContent;

	// Logout user
	const { logout } = useUser();

	const handleLogout = async () => {
		await api.SSO.logout();
		logout();
	};

	return (
		<div className={sidebarClass}>
			{/* Sidebar header */}
			<div className="sidebar_head">
				<button className="menu_toggle" onClick={() => toggleSidebar()}>
					menu toggle
				</button>
				<NavLink className="logo" to="/">
					<img src="/assets/img/logos/logo_bp_slim_w2x.png" alt="alea logo" />
				</NavLink>
				<div className="bottom_divider">
					<div className="separator" />
				</div>
				<DateComponent sidebarToggled={sidebarToggled} />
			</div>

			{/* Sidebar menu */}
			<ul className="app_menu">
				<SidebarLink to="/" exact svg="44_layout">
					{t('dashboard.title')}
				</SidebarLink>
				{/* Offers Plan */}
				<DropdownWrapper subject="prematch">
					<SidebarLink icon="file-tree" to="/offers-plan">
						{t('offersPlan.title')}
					</SidebarLink>
				</DropdownWrapper>

				{/* Administration */}
				<DropdownWrapper subject="offer">
					<AdministrationDropdown />
				</DropdownWrapper>
				{/* Pairing */}
				<DropdownWrapper subject="offer">
					<PairingDropdown />
				</DropdownWrapper>
				{/* Prematch Offer */}
				<DropdownWrapper subject="prematch">
					<SidebarDropdown icon="soccer-field" path="/prematch" text={t('prematch.title')}>
						<SidebarLink to="/prematch/controller" icon="circle-medium">
							{t('prematch.controller.title')}
						</SidebarLink>
						<SidebarLink to="/prematch/bonus-tips" icon="circle-medium">
							{t('bonusTips.title')}
						</SidebarLink>
						<SidebarLink to="/prematch/create-events-manually" icon="circle-medium">
							{t('createEventsManually.title')}
						</SidebarLink>
					</SidebarDropdown>
				</DropdownWrapper>
				{/* Live Offer */}
				<DropdownWrapper bypassCasl>
					<SidebarDropdown svg="04_Live" path="/live" text={t('live.liveOffer')}>
						<SidebarLink to="/live/pairing" icon="circle-medium" subject="offer">
							{t('live.livePairing')}
						</SidebarLink>
						<SidebarLink to="/live/administration" icon="circle-medium" subject="live">
							{t('live.matchControl')}
						</SidebarLink>
						<SidebarLink to="/live/lms-pairing" icon="circle-medium" subject="live">
							{t('live.lmsPairing')}
						</SidebarLink>
					</SidebarDropdown>
				</DropdownWrapper>
				{/* Lists */}
				<DropdownWrapper subject="prematch">
					<SidebarDropdown icon={'file-document-outline'} path="/lists" text={'Lists'}>
						<SidebarLink to="/lists/tables" icon="circle-medium">
							{t('listTables.shortTitle')}
						</SidebarLink>
						<SidebarLink to="/lists/templates" icon="circle-medium">
							{t('listTemplates.shortTitle')}
						</SidebarLink>
						<SidebarLink to="/lists/builder" icon="circle-medium">
							{t('listBuilder.shortTitle')}
						</SidebarLink>
						<SidebarLink to="/lists/print-administration" icon="circle-medium">
							{t('listBuilder.printAdministration')}
						</SidebarLink>
						<SidebarLink to="/lists/export-specials" icon="circle-medium">
							{t('listBuilder.export.specials')}
						</SidebarLink>
						<SidebarLink to="/lists/daily-offer" icon="circle-medium">
							{t('listBuilder.export.dailyOffer')}
						</SidebarLink>
						<SidebarLink to="/lists/time-offer" icon="circle-medium">
							{t('listBuilder.export.timeOffer')}
						</SidebarLink>
					</SidebarDropdown>
				</DropdownWrapper>

				{/* Risk */}
				<DropdownWrapper subject="risk">
					<SidebarDropdown svg="08_Autorizacija" path="/risk" text={t('risk.shortTitle')}>
						<SidebarLink to="/risk/controll" icon="circle-medium">
							{t('risk.shortTitle')}
						</SidebarLink>
						{/* <SidebarLink to="/risk/categories" icon="circle-medium">
							{t('risk.categories.title')}
						</SidebarLink> */}
						<SidebarLink to="/risk/tags" icon="circle-medium">
							{t('risk.tags.shortTitle')}
						</SidebarLink>

						<SidebarLink to="/risk/risk-groups" icon="circle-medium">
							{t('riskGroups.shortTitle')}
						</SidebarLink>
					</SidebarDropdown>
				</DropdownWrapper>

				{/* Reports */}
				<DropdownWrapper subject="reporting">
					<SidebarDropdown icon={'chart-box'} path="/reports" text={t('reports.title')}>
						<SidebarLink to="/reports/tickets" icon="circle-medium">
							{t('risk.tickets.title')}
						</SidebarLink>
						<SidebarLink to="/reports/tickets-report" icon="circle-medium">
							{t('reports.ticketsReport.title')}
						</SidebarLink>
						<SidebarLink to="/reports/event-stats" icon="circle-medium">
							{t('eventStats.title')}
						</SidebarLink>
						<SidebarLink to="/reports/reservations" icon="circle-medium">
							{t('reports.reservations.title')}
						</SidebarLink>
						<SidebarLink to="/reports/cash-flow" icon="circle-medium">
							{t('reports.cashflow.title')}
						</SidebarLink>
						<SidebarLink to="/reports/promotions-report" icon="circle-medium">
							{t('reports.promotions.title')}
						</SidebarLink>
						<SidebarLink to="/reports/basic-report" icon="circle-medium">
							{t('reports.basic.title')}
						</SidebarLink>
						<SidebarLink to="/reports/verification" icon="circle-medium">
							{t('reports.verification.title')}
						</SidebarLink>
						<SidebarLink to="/reports/payment-transactions" icon="circle-medium">
							{t('reports.paymentTransactions.title')}
						</SidebarLink>
						<SidebarLink to="/reports/transactions" icon="circle-medium">
							{t('reports.transactionsReports.title')}
						</SidebarLink>
						<SidebarLink to="/reports/online-report" icon="circle-medium">
							{t('reports.onlineReport.title')}
						</SidebarLink>
						<SidebarLink to="/reports/aml-report" icon="circle-medium">
							{t('reports.amlReports.title')}
						</SidebarLink>
					</SidebarDropdown>
				</DropdownWrapper>

				{/* Cashout */}
				<DropdownWrapper subject="risk">
					<SidebarDropdown icon="cash-check" path="/cashout" text={t('cashout.shortTitle')}>
						<SidebarLink to="/cashout/control" icon="circle-medium">
							{t('cashout.shortTitle')}
						</SidebarLink>
						<SidebarLink to="/cashout/offer" icon="circle-medium">
							{t('cashout.offer')}
						</SidebarLink>
						<SidebarLink to="/cashout/tickets" icon="circle-medium">
							{t('risk.tickets.title')}
						</SidebarLink>
					</SidebarDropdown>
				</DropdownWrapper>

				{/* Results */}
				<DropdownWrapper subject="results">
					<SidebarDropdown svg="05_Rezultovanje" path="/results" text={t('results.title')}>
						<SidebarLink to="/results/events" icon="circle-medium">
							{t('eventsAdministration.shortTitle')}
						</SidebarLink>
						<SidebarLink to="/results/playerProps" icon="circle-medium">
							{t('playerPropsResults.title')}
						</SidebarLink>
					</SidebarDropdown>
				</DropdownWrapper>

				{/* Parameters */}
				<DropdownWrapper bypassCasl>
					<SidebarDropdown icon="cogs" path="/parameters" text={t('parameters')}>
						<SidebarLink to="/parameters/geo" icon="circle-medium">
							{t('geo.title')}
						</SidebarLink>
						<SidebarLink to="/parameters/conditions" icon="circle-medium" subject="risk">
							{t('conditions.title')}
						</SidebarLink>
						<SidebarLink to="/parameters/limits" icon="circle-medium" subject="risk">
							{t('limits.title')}
						</SidebarLink>
						<SidebarLink to="/parameters/authorization" icon="circle-medium" subject="risk">
							{t('authParams.title')}
						</SidebarLink>
					</SidebarDropdown>
				</DropdownWrapper>

				{/* Internet */}
				<DropdownWrapper subject="online">
					<SidebarDropdown icon="card-account-details" path="/internet" text={t('internet.shortTitle')}>
						<SidebarLink to="/internet/users" icon="circle-medium">
							{t('usersAdministrations.shortTitle')}
						</SidebarLink>
						<SidebarLink to="/internet/transactions" icon="circle-medium">
							{t('transactions.title')}
						</SidebarLink>
						<SidebarLink to="/internet/bonuses" icon="circle-medium">
							{t('internet.userBonuses.title')}
						</SidebarLink>
						<SidebarLink to="/internet/voucher-series" icon="circle-medium">
							{t('vouchers.vouchersAdministration')}
						</SidebarLink>
						<SidebarLink to="/internet/email-service" icon="circle-medium">
							{t('emailService.shortTitle')}
						</SidebarLink>
						<SidebarLink to="/internet/system-notifications" icon="circle-medium">
							{t('systemNotifications.title')}
						</SidebarLink>
						<SidebarLink to="/internet/referral-affiliate" icon="circle-medium">
							{t('referralAndAffiliate.title')}
						</SidebarLink>
					</SidebarDropdown>
				</DropdownWrapper>

				{/* Online support */}
				<DropdownWrapper bypassCasl>
					<SidebarDropdown icon="face-agent" path="/online-support" text={t('onlineSupport.title')}>
						<SidebarLink to="/online-support/users" icon="circle-medium" subject="support">
							{t('usersAdministrations.shortTitle')}
						</SidebarLink>
						<SidebarLink to="/online-support/banners" icon="circle-medium" subject="promo">
							{t('banners.shortTitle')}
						</SidebarLink>
						<SidebarLink to="/online-support/templates" icon="circle-medium" subject="support">
							{t('supportTemplates.shortTitle')}
						</SidebarLink>
					</SidebarDropdown>
				</DropdownWrapper>

				{/* Betmedia */}
				<DropdownWrapper subject="live">
					<SidebarDropdown icon="cogs" path="/betmedia" text={t('betMedia.title')}>
						<SidebarLink to="/betmedia/devices" icon="circle-medium">
							{t('betMedia.devices')}
						</SidebarLink>
						<SidebarLink to="/betmedia/unassigned" icon="circle-medium">
							{t('betMedia.unassigned')}
						</SidebarLink>
					</SidebarDropdown>
				</DropdownWrapper>

				{/* System */}
				<DropdownWrapper>
					<SidebarDropdown icon="tune" path="/system" text={'System'}>
						<SidebarLink to="/system/integrations" icon="circle-medium" subject="offer">
							{t('system.integrations')}
						</SidebarLink>
						<SidebarLink to="/system/users" icon="circle-medium" subject="superuser">
							{t('users.title')}
						</SidebarLink>
						<SidebarLink to="/system/roles" icon="circle-medium" subject="superuser">
							{t('roles.title')}
						</SidebarLink>
						<SidebarLink to="/system/logging" icon="circle-medium" subject="superuser">
							{t('system.logging')}
						</SidebarLink>
						<SidebarLink to="/system/settings" icon="circle-medium">
							{t('settings.title')}
						</SidebarLink>
					</SidebarDropdown>
				</DropdownWrapper>
				{/* Logout */}
				<SidebarButton icon={'fingerprint'} handleClick={handleLogout}>
					{t('login.logout')}
				</SidebarButton>
			</ul>

			{/* Sidebar copyright */}
			<span className="sidebar_copyrights">Copyrights &copy; Alea Control</span>

			<div className="empty_div"></div>
		</div>
	);
};

export default withRouter(Sidebar);
